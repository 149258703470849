import React, { useState } from "react"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"
import "../../../css/bootstrap-tagsinput.css"
import _uniqueId from "lodash/uniqueId"
import UserExpRow from "./UserExperienceFlow/UserExpRow"
import ExistingUserExpRow from "./UserExperienceFlow/ExistingUserExpRow"

const UserExperienceFlow = ({ showForThemPanel, additionalOptions, setAdditionalOptions, driveAudio }) => {
  const [userExpRow, setUserExpRow] = useState([])

  const addNewDataRow = () => {
    setUserExpRow([...userExpRow, _uniqueId("data_row_")])
  }
  const removeNewDataRow = removeIndex => {
    let tempDataRows = []
    let existingDataRows = []
    userExpRow.map((dataRow, dataRowIndex) => {
      dataRowIndex = additionalOptions.length + dataRowIndex
      let length = additionalOptions.length + userExpRow.length
      if (removeIndex !== dataRowIndex) {
        tempDataRows = [...tempDataRows, dataRow]
      }
      if (dataRowIndex === length - 1) {
        setUserExpRow([...tempDataRows])
      }
    })
    if (additionalOptions.length > 0) {
      additionalOptions.map((dataRow, dataRowIndex) => {
        if (removeIndex !== dataRowIndex) {
          existingDataRows = [...existingDataRows, dataRow]
        }
        if (dataRowIndex === additionalOptions.length - 1) {
          setAdditionalOptions([...existingDataRows])
        }
      })
    }
  }

  return (
    <div className={`col-lg-12 mb-3${!showForThemPanel ? " d-none" : ""}`} id="for_them_panel">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0" className="panel panel-pink">
          <Accordion.Header>
            <span className="panel-heading-icon">
              <i className="bi bi-box-arrow-right" />
            </span>
            <span className="panel-heading-title">FOR THEM: Callers will...</span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="forThemOptionContainer">
              {additionalOptions.length > 0 &&
                additionalOptions.map((data, index) => (
                  <ExistingUserExpRow
                    removeNewDataRow={removeNewDataRow}
                    data={data}
                    index={index}
                    key={index}
                    driveAudio={driveAudio}
                  />
                ))}

              {userExpRow.length > 0 &&
                userExpRow.map((dataRow, dataRowIndex) => (
                  <UserExpRow
                    removeNewDataRow={removeNewDataRow}
                    key={dataRow}
                    dataRowIndex={additionalOptions.length + dataRowIndex}
                    driveAudio={driveAudio}
                  />
                ))}
              <div className="text-center btnAddNewFTOptionContainer mb-2">
                <OverlayTrigger placement="top" overlay={<Tooltip>Add Option</Tooltip>}>
                  <button
                    type="button"
                    className="btnAddNewGreenDashed btnAddNewFTOption"
                    data-bs-toggle="tooltip"
                    onClick={addNewDataRow}
                  >
                    <i className="bi bi-plus-lg" />
                  </button>
                </OverlayTrigger>
              </div>
              <div className="text-center endCallSpan">End Call</div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default UserExperienceFlow
