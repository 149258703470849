import React, { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const ExistingUserExpRow = ({ removeNewDataRow, data, index, driveAudio }) => {
  const [removeDataRow, setRemoveDataRow] = useState(false),
    [detailType, setDetailType] = useState(data.set_option),
    [audioMp3, setAudioMp3] = useState(data.audio_mp3),
    [getForwarded, setGetForwarded] = useState(data.get_forwarded),
    [contactNumber, setContactNumber] = useState(data.contact_number),
    [conversionAudioMp3, setConversionAudioMp3] = useState(data.conversion_audio_mp3)

  useEffect(() => {
    if (removeDataRow) {
      removeNewDataRow(index)
    }
  })

  return (
    <div className="pinkOutlineBox">
      <OverlayTrigger placement="top" overlay={<Tooltip>Remove</Tooltip>}>
        <button
          className="btnRemoveFTOption"
          data-bs-toggle="tooltip"
          onClick={() => {
            setRemoveDataRow(true)
          }}
        >
          <i className="bi bi-x-lg" />
        </button>
      </OverlayTrigger>

      <div className="row">
        <div className="col-lg-12">
          <div className="row row-cols-sm-auto g-2 forThemOptionContainer">
            <div className="col-12">
              <select
                className="form-select forThemOption"
                name={`additional_options_${index}`}
                value={detailType}
                onChange={e => setDetailType(e.target.value)}
              >
                <option>== Select ==</option>
                <option value="HearAudio">Hear Audio</option>
                <option value="GetForwarded">Get Forwarded</option>
                <option value="StartConversation">Start Conversation</option>
                <option value="RecordVoicemail">Record Voicemail</option>
              </select>
            </div>
            <div
              className={`col-12 forThemOpt forThemOptHearAudio${
                detailType !== "HearAudio" ? " d-none" : ""
              }`}
            >
              <select
                className="form-select"
                name={`audio_mp3_${index}`}
                value={audioMp3}
                onChange={e => setAudioMp3(e.target.value)}
              >
                {driveAudio.map((audio, key) => (
                  <option value={audio.id} key={key}>
                    {audio.nameDisplay}
                  </option>
                ))}
              </select>
            </div>
            <div
              className={`col-12 forThemOpt forThemOptGetForwarded${
                detailType !== "GetForwarded" ? " d-none" : ""
              }`}
            >
              <div className="row row-cols-sm-auto g-2 align-items-center">
                <div className="col-12">for</div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={getForwarded}
                    onkeypress="if (!window.__cfRLUnblockHandlers) return false; return onlyNumberKey(event)"
                    maxLength={3}
                    style={{ width: 50 }}
                    name={`get_forwarded_${index}`}
                  />
                </div>
                <div className="col-12">rings to</div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={contactNumber}
                    onkeypress="if (!window.__cfRLUnblockHandlers) return false; return onlyNumberKey(event)"
                    maxLength={15}
                    name={`contact_number_${index}`}
                  />
                </div>
              </div>
            </div>
            <div
              className={`col-12 forThemOpt forThemOptRecordVoicemail${
                detailType !== "StartConversation" && detailType !== "RecordVoicemail" ? " d-none" : ""
              }`}
            >
              <div className="row row-cols-sm-auto g-2 align-items-center">
                <div className="col-12">
                  <select name={`conversion_play_audio_${index}`} className="form-select">
                    <option value="Play Audio">Play Audio</option>
                  </select>
                </div>
                <div className="col-12 greenCallForwaringPlayAudioSayOpt greenCallForwaringPlayAudioSayOptAudio">
                  <select
                    className="form-select"
                    name={`conversion_audio_mp3_${index}`}
                    value={conversionAudioMp3}
                    onChange={e => setConversionAudioMp3(e.target.value)}
                  >
                    {driveAudio.map((audio, key) => (
                      <option value={audio.id} key={key}>
                        {audio.nameDisplay}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={`col-12${detailType === "StartConversation" ? " d-none" : ""}`}>
                  , then record voicemail.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ExistingUserExpRow
