import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import PhoneDetail from "../../../components/Drive/Phone/Detail.js"
import ReduxWrapper from "../../../redux/reduxWrapper"

const PhoneDetails = props => {
  return <PrivateRoute component={PhoneDetail} location={props.location} selectedPhoneId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<PhoneDetails {...props} />} />
export default WrappedPage
