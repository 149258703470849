import React from "react"
import { Form } from "react-bootstrap"

const Language = ({ language }) => {
  return (
    <div className="col-lg-12 mb-3">
      <div className="colHeading">
        <strong>
          <i className="bi bi-globe" /> Language
        </strong>
      </div>
      <div className="dh-block-body">
        <Form.Select
          name="language"
          id="Language"
          className="form-select"
          defaultValue={language}
          key={language}
        >
          <option value="af">Afrikaans</option>
          <option value="sq">Albanian</option>
          <option value="ar-dz">Arabic (Algeria)</option>
          <option value="ar-bh">Arabic (Bahrain)</option>
          <option value="ar-eg">Arabic (Egypt)</option>
          <option value="ar-iq">Arabic (Iraq)</option>
          <option value="ar-jo">Arabic (Jordan)</option>
          <option value="ar-kw">Arabic (Kuwait)</option>
          <option value="ar-lb">Arabic (Lebanon)</option>
          <option value="ar-ly">Arabic (Libya)</option>
          <option value="ar-ma">Arabic (Morocco)</option>
          <option value="ar-om">Arabic (Oman)</option>
          <option value="ar-qa">Arabic (Qatar)</option>
          <option value="ar-sa">Arabic (Saudi Arabia)</option>
          <option value="ar-sy">Arabic (Syria)</option>
          <option value="ar-tn">Arabic (Tunisia)</option>
          <option value="ar-ae">Arabic (U.a.e.)</option>
          <option value="ar-ye">Arabic (Yemen)</option>
          <option value="eu">Basque</option>
          <option value="be">Belarusian</option>
          <option value="bs">Bosnian</option>
          <option value="bg">Bulgarian</option>
          <option value="ca">Catalan</option>
          <option value="zh-hk">Chinese (Hong Kong)</option>
          <option value="zh-cn">Chinese (Prc)</option>
          <option value="zh-sg">Chinese (Singapore)</option>
          <option value="zh-tw">Chinese (Taiwan)</option>
          <option value="hr">Croatian</option>
          <option value="cs">Czech</option>
          <option value="da">Danish</option>
          <option value="nl-be">Dutch (Belgium)</option>
          <option value="nl">Dutch (Standard)</option>
          <option value="en">English</option>
          <option value="en-au">English (Australia)</option>
          <option value="en-bz">English (Belize)</option>
          <option value="en-ca">English (Canada)</option>
          <option value="en-ie">English (Ireland)</option>
          <option value="en-jm">English (Jamaica)</option>
          <option value="en-nz">English (New Zealand)</option>
          <option value="en-za">English (South Africa)</option>
          <option value="en-tt">English (Trinidad)</option>
          <option value="en">English (United Kingdom)</option>
          <option value="en-us">English (United States)</option>
          <option value="et">Estonian</option>
          <option value="fo">Faeroese</option>
          <option value="fa">Farsi</option>
          <option value="fi">Finnish</option>
          <option value="fr-be">French (Belgium)</option>
          <option value="fr-ca">French (Canada)</option>
          <option value="fr-lu">French (Luxembourg)</option>
          <option value="fr">French (Standard)</option>
          <option value="fr-ch">French (Switzerland)</option>
          <option value="gd">Gaelic (Scotland)</option>
          <option value="de-at">German (Austria)</option>
          <option value="de-li">German (Liechtenstein)</option>
          <option value="de-lu">German (Luxembourg)</option>
          <option value="de">German (Standard)</option>
          <option value="de-ch">German (Switzerland)</option>
          <option value="el">Greek</option>
          <option value="he">Hebrew</option>
          <option value="hi">Hindi</option>
          <option value="hu">Hungarian</option>
          <option value="is">Icelandic</option>
          <option value="id">Indonesian</option>
          <option value="ga">Irish</option>
          <option value="it">Italian (Standard)</option>
          <option value="it-ch">Italian (Switzerland)</option>
          <option value="ja">Japanese</option>
          <option value="ko">Korean</option>
          <option value="ko">Korean (Johab)</option>
          <option value="ku">Kurdish</option>
          <option value="lv">Latvian</option>
          <option value="lt">Lithuanian</option>
          <option value="mk">Macedonian (Fyrom)</option>
          <option value="ml">Malayalam</option>
          <option value="ms">Malaysian</option>
          <option value="mt">Maltese</option>
          <option value="sr-me">Montenegrin</option>
          <option value="no">Norwegian</option>
          <option value="nb">Norwegian (Bokmål)</option>
          <option value="nn">Norwegian (Nynorsk)</option>
          <option value="pl">Polish</option>
          <option value="pt-br">Portuguese (Brazil)</option>
          <option value="pt">Portuguese (Portugal)</option>
          <option value="pa">Punjabi</option>
          <option value="rm">Rhaeto-Romanic</option>
          <option value="ro">Romanian</option>
          <option value="ro-md">Romanian (Republic Of Moldova)</option>
          <option value="ru">Russian</option>
          <option value="ru-md">Russian (Republic Of Moldova)</option>
          <option value="sr">Serbian</option>
          <option value="sk">Slovak</option>
          <option value="sl">Slovenian</option>
          <option value="sb">Sorbian</option>
          <option value="es-ar"> Spanish (Argentina)</option>
          <option value="es-bo">Spanish (Bolivia)</option>
          <option value="es-cl">Spanish (Chile)</option>
          <option value="es-co">Spanish (Colombia)</option>
          <option value="es-cr">Spanish (Costa Rica)</option>
          <option value="es-do">Spanish (Dominican Republic)</option>
          <option value="es-ec">Spanish (Ecuador)</option>
          <option value="es-sv">Spanish (El Salvador)</option>
          <option value="es-gt">Spanish (Guatemala)</option>
          <option value="es-hn">Spanish (Honduras)</option>
          <option value="es-mx">Spanish (Mexico)</option>
          <option value="es-ni">Spanish (Nicaragua)</option>
          <option value="es-pa">Spanish (Panama)</option>
          <option value="es-py">Spanish (Paraguay)</option>
          <option value="es-pe">Spanish (Peru)</option>
          <option value="es-pr">Spanish (Puerto Rico)</option>
          <option value="es">Spanish (Spain)</option>
          <option value="es-uy">Spanish (Uruguay)</option>
          <option value="es-ve">Spanish (Venezuela)</option>
          <option value="sw">Swahili</option>
          <option value="sv">Swedish</option>
          <option value="sv-fi">Swedish (Finland)</option>
          <option value="th">Thai</option>
          <option value="ts">Tsonga</option>
          <option value="tn">Tswana</option>
          <option value="tr">Turkish</option>
          <option value="uk">Ukrainian</option>
          <option value="ur">Urdu</option>
          <option value="ve">Venda</option>
          <option value="vi">Vietnamese</option>
          <option value="cy">Welsh</option>
          <option value="xh">Xhosa</option>
          <option value="ji">Yiddish</option>
          <option value="zu">Zulu</option>
        </Form.Select>
      </div>
    </div>
  )
}

export default Language
