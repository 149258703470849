import React, { useEffect, useState } from "react"
import { Accordion } from "react-bootstrap"
import "../../../css/bootstrap-tagsinput.css"
import Language from "./MoreOptions/language"
import Tags from "./MoreOptions/tags"
import { Link } from "gatsby"
import { getUserName } from "../Doc/Service/docHelper"

const MoreOptions = ({
  language,
  tags,
  setTags,
  tempTags,
  setTempTags,
  showMoreDetailsPanel,
  recordingIs,
  setRecordingIs,
  recordingOpts,
  setRecordingOpts,
  missedCall,
  setMissedCall,
  missedCallCounterInput,
  setMissedCallCounterInput,
  spamCallerChallenge,
  setSpamCallerChallenge,
  whisper,
  setWhisper,
  playAudio,
  setPlayAudio,
  audioMp3,
  setAudioMp3,
  whisperSmsInput,
  setWhisperSmsInput,
  whisperSayInput,
  setWhisperSayInput,
  driveAudio,
  loginUserId,
  driveFileUrl,
}) => {
  const characterLength = 140
  const twilioVoiceWebhook = `${process.env.GATSBY_API_URL}/api/twilio-communication/incoming/voicemail/details/${loginUserId}`
  const twilioSmsWebhook = `${process.env.GATSBY_API_URL}/incoming/twilio/sms/${loginUserId}`
  const twilioFailWebhook = `${process.env.GATSBY_API_URL}/incoming/twilio/fail/${loginUserId}`
  const twilioPhoneXml = `${process.env.GATSBY_API_URL}/${getUserName()}/${driveFileUrl}/index.xml`

  const [count, setCount] = useState(0)

  const handleRemainingCharacters = e => {
    setCount(characterLength - e.target.value.length)
    setMissedCallCounterInput(e.target.value)
  }

  useEffect(() => {
    setCount(characterLength - missedCallCounterInput.length)
  }, [missedCallCounterInput])

  return (
    <div className={`col-lg-12 mb-3${!showMoreDetailsPanel ? " d-none" : ""}`} id="more_panel">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1" className="panel panel-grey">
          <Accordion.Header>
            <span className="panel-heading-icon">
              <i className="bi bi-three-dots" />
            </span>
            <span className="panel-heading-title">More Options</span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="col-lg-12 mb-3">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-tags-fill" /> Tags
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    <Tags tags={tags} setTags={setTags} tempTags={tempTags} setTempTags={setTempTags} />
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-arrow-bar-right" /> Whisper
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    <select
                      className="form-select WhisperOpt"
                      name="whisper"
                      onChange={e => {
                        setWhisper(e.target.value)
                      }}
                      value={whisper}
                    >
                      <option>== Select Option ==</option>
                      <option value="Whisper_Audio">Whisper (Audio)</option>
                      <option value="Whisper_SMS">Whisper (SMS / Text)</option>
                      <option value="Say">Say (Text to Speech)</option>
                    </select>
                    <div
                      className={`mt-2 greenCallForwaringOpt greenCallForwaringOpt_Whisper_Audio${
                        whisper !== "Whisper_Audio" ? " d-none" : ""
                      }`}
                    >
                      <div className="row row-cols-sm-auto g-2 align-items-center">
                        <div className="col-12">
                          <select
                            className="form-select"
                            name="play_audio"
                            value={playAudio}
                            onChange={e => {
                              setPlayAudio(e.target.value)
                            }}
                          >
                            <option value={"Play Audio"}>Play Audio</option>
                          </select>
                        </div>
                        <div className="col-12 greenCallForwaringPlayAudioSayOpt greenCallForwaringPlayAudioSayOptAudio">
                          <select
                            className="form-select"
                            name="audiomp3"
                            value={audioMp3}
                            onChange={e => {
                              setAudioMp3(e.target.value)
                            }}
                          >
                            {driveAudio.map((audio, key) => (
                              <option value={audio.id} key={key}>
                                {audio.nameDisplay}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`mt-2 greenCallForwaringOpt greenCallForwaringOpt_Whisper_SMS${
                        whisper !== "Whisper_SMS" ? " d-none" : ""
                      }`}
                    >
                      <div className="row row-cols-sm-auto g-2 align-items-center">
                        <div className="col-lg-12 greenCallForwaringPlayAudioSayOpt greenCallForwaringPlayAudioSayOptSay">
                          <input
                            type="text"
                            className="form-control"
                            name="whisper_sms_input"
                            id="whisper_sms_input"
                            value={whisperSmsInput}
                            onChange={e => {
                              setWhisperSmsInput(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`mt-2 greenCallForwaringOpt greenCallForwaringOpt_Say${
                        whisper !== "Say" ? " d-none" : ""
                      }`}
                    >
                      <input
                        type="text"
                        className="form-control"
                        name="whisper_sys_input"
                        id="whisper_sys_input"
                        value={whisperSayInput}
                        onChange={e => {
                          setWhisperSayInput(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-record-circle" /> Recording
                    </strong>
                  </div>

                  <div className="dh-block-body">
                    <div className="row row-cols-sm-auto g-2 align-items-center">
                      <div className="col-12">Recording is</div>
                      <div className="col-12">
                        <div className="toggleSwitch">
                          <input
                            type="checkbox"
                            name="Recording_is"
                            id="Recording_is"
                            defaultValue={recordingIs}
                            checked={recordingIs ? "checked" : ""}
                            onChange={() => setRecordingIs(!recordingIs)}
                          />

                          <label />
                        </div>
                      </div>
                      <div className={`col-12 recoding_opts${!recordingIs ? " d-none" : ""}`}>
                        <select
                          className="form-select"
                          name="Recording_opts"
                          id="Recording_opts"
                          onChange={e => {
                            setRecordingOpts(e.target.value)
                          }}
                          value={recordingOpts}
                        >
                          <option value={60}>60 Seconds</option>
                          <option value={90}>90 Seconds</option>
                          <option value={120}>120 Seconds</option>
                          <option value={360}>6 Minutes</option>
                          <option value="Entire Call">Entire Call</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-reply-all-fill" /> Enable Missed Call Auto Text-Back
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    <div className="row row-cols-sm-auto g-2 align-items-center">
                      <div className="col-12">
                        <div className="toggleSwitch">
                          <input
                            type="checkbox"
                            name="missed_call"
                            id="missed_call"
                            defaultValue={missedCall}
                            checked={missedCall ? "checked" : ""}
                            onChange={() => setMissedCall(!missedCall)}
                          />
                          <label />
                        </div>
                      </div>

                      <div
                        className={`col-12 col-lg-6 enableMissedCallTextInput${!missedCall ? " d-none" : ""}`}
                      >
                        <input
                          type="text"
                          name="missed_call_counter_input"
                          className="form-control missedCallCounterInput"
                          placeholder="How can I help?"
                          value={missedCallCounterInput}
                          maxLength={characterLength}
                          onChange={e => {
                            handleRemainingCharacters(e)
                          }}
                        />
                      </div>
                      <div className={`col-12 enableMissedCallTextInput${!missedCall ? " d-none" : ""}`}>
                        <span className="missedCallCounter">{count} remaining</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-funnel-fill" /> Spam Caller Filtering
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <div className="row row-cols-sm-auto g-2 align-items-center">
                        <div className="col-12">Spam Caller Challenge is</div>
                        <div className="col-12">
                          <div className="toggleSwitch">
                            <input
                              type="checkbox"
                              name="spam_caller_challenge"
                              id="spam_caller_challenge"
                              defaultValue={spamCallerChallenge}
                              checked={spamCallerChallenge ? "checked" : ""}
                              onChange={() => setSpamCallerChallenge(!spamCallerChallenge)}
                            />
                            <label />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Language language={language} />

                  <div className="col-lg-12 mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-plugin" /> API Connections
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <div className="mb-3">
                        <div>
                          <div className="col-md-5">
                            <b>Twilio Voice Webhook</b>
                          </div>
                          <span>
                            <Link to={twilioVoiceWebhook}> {twilioVoiceWebhook}</Link>
                          </span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div>
                          <div className="col-md-5">
                            <b>Twilio Message/SMS Webhook</b>
                          </div>
                          <span>
                            <Link to={twilioSmsWebhook}> {twilioSmsWebhook}</Link>
                          </span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div>
                          <div className="col-md-5">
                            <b>Twilio Fail Webhook</b>
                          </div>
                          <span>
                            <Link to={twilioFailWebhook}> {twilioFailWebhook}</Link>
                          </span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div>
                          <div className="col-md-5">
                            <b>Twilio XML</b>
                          </div>
                          <span>
                            <Link to={twilioPhoneXml}> {twilioPhoneXml}</Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-trash" /> Drop Number
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <a href="#" className="dashedLinked">
                        Release this number
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default MoreOptions
